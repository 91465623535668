import React, {useState} from 'react';
import styled from 'styled-components';
import {Link, graphql} from 'gatsby';
import SVG from 'react-inlinesvg';
import HeaderWithLine from '../components/HeaderWithLine';
import ImagesList from '../components/ImagesList';
import AlbumsList from '../components/AlbumsList';
import backArrow from '../../static/right-arrow.svg';

const Filter = styled.span`
  list-style: none;
  color: ${({theme}) => theme.black};
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before,
  &.active ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({theme}) => theme.black};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({theme}) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const FilterBox = styled.div`
  ${({theme}) => theme.mq.desktop} {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
`;

const FiltersWrapper = styled.div`
  text-align: left;
  padding: 0 2rem 0 2rem;
  padding-bottom: 1rem;
  grid-template-columns: 80px 85px 85px;
  grid-gap: 1rem;
  display: grid;

  ${({theme}) => theme.mq.desktop} {
    grid-template-columns: 80px 85px 85px;
  }
`;

const FiltersWrapperGallery = styled.div`
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 2rem;
  padding-bottom: 1rem;

  ${({theme}) => theme.mq.desktop} {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 2rem;

    text-align: ${({hasFilters}) => {
      return hasFilters ? 'right' : 'left';
    }}
  }
;
}
`;

const StyledLink = styled(Link)``;

const StyledSVG = styled.div`
  height: 15px;
  width: 15px;
  cursor: pointer;
  display: inline-block;
  opacity: 0.8;
  transform: rotate(180deg);
  vertical-align: middle;
  padding: 0 10px;

  svg {
    height: 100%;
    width: auto;
    opacity: 0.8;
    margin-top: auto;
    margin-bottom: auto;
  }

  path {
    fill: black;
  }
`;

const GalleryTop = styled.div`
  margin-top: 200px;
`;

function isGalleryType(galleryTypes, id) {
    return galleryTypes.find((type) => type.id === id);
}

function isImageType(galleryImageTypes, name) {
    return galleryImageTypes.find((type) => type.name === name);
}

function getImagesByGalleryType(id, activeImageType, images) {
    return images.filter(
        (img) =>
            isGalleryType(img.galleryType, id) &&
            (isImageType(img.galleryImageType, activeImageType) ||
                activeImageType === 'all')
    );
}

export default function GalleryTypePage({data}) {
    const [activeImageType, setActiveImageType] = useState('all');

    const imagesAndAlbums = getImagesByGalleryType(
        data.galleryType.id,
        activeImageType,
        data.images.nodes
    );

    const allImageTypes = data.galleryTypes.nodes.map((type) => type.name);
    const albums = imagesAndAlbums.filter((img) => img.isAlbum);
    const images = imagesAndAlbums
        .filter((img) => !img.isAlbum)
        .map((img) => img.image);

    return (
        <>
            <GalleryTop>
                <HeaderWithLine>{data.galleryType.name}</HeaderWithLine>
                <FilterBox>
                    {data.galleryType.hasFilters ? (
                        <FiltersWrapper>
                            <Filter
                                className={activeImageType === 'all' ? 'active' : ''}
                                onClick={() => setActiveImageType('all')}
                            >
                                wszystko
                            </Filter>
                            {allImageTypes.map((type) => (
                                <Filter
                                    key={type}
                                    className={type === activeImageType ? 'active' : ''}
                                    onClick={() => setActiveImageType(type)}
                                >
                                    {type}
                                </Filter>
                            ))}
                        </FiltersWrapper>
                    ) : (
                        ''
                    )}
                    <FiltersWrapperGallery hasFilters={data.galleryType.hasFilters}>
                        <StyledLink to="/galeria">
                            <StyledSVG>
                                <SVG src={backArrow}/>
                            </StyledSVG>
                            powrót do galerii
                        </StyledLink>
                    </FiltersWrapperGallery>
                </FilterBox>
            </GalleryTop>
            <div>{albums.length > 0 ? <AlbumsList albums={albums}/> : ''}</div>
            <div>{images.length > 0 ? <ImagesList images={images}/> : ''}</div>
        </>
    );
}

export const query = graphql`
  query ImagesQuery($slug: String!) {
    galleryType: sanityGalleryType(slug: { current: { eq: $slug } }) {
      name
      id
      hasFilters
    }
    galleryTypes: allSanityGalleryImageType {
      nodes {
        name
        id
      }
    }
    images: allSanityGallery {
      nodes {
        id
        name
        isAlbum
        slug {
          current
        }
        galleryType {
          id
          name
        }
        galleryImageType {
          id
          name
        }
        image {
          asset {
            id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
