import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { Link } from 'gatsby';

const ImageGridStyles = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const SingleImageWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 0.5s;

  &:hover {
    transition-duration: 0.5s;
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 30rem;
  object-fit: cover;
`;

const SingleImageDescription = styled.div`
  text-align: center;
  padding: 1.2rem;
`;

const SingleImageLink = styled(Link)`
  text-decoration: none;
`;

function SingleImage({ image }) {
  return (
    <SingleImageWrapper>
      <StyledImage image={image.image.asset.gatsbyImageData} alt={image.name} />
      {image.name && image.name.length > 0 ? (
        <SingleImageDescription>{image.name}</SingleImageDescription>
      ) : (
        ''
      )}
    </SingleImageWrapper>
  );
}

export default function AlbumsList({ albums }) {
  return (
    <>
      <ImageGridStyles>
        {albums &&
          albums.map((album) => (
            <SingleImageLink to={album.slug.current} key={album.name}>
              <SingleImage image={album} />
            </SingleImageLink>
          ))}
      </ImageGridStyles>
    </>
  );
}
